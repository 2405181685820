
import http from "@/http";
import { Breadcrumb, Checklist } from "@/interfaces";
import Vue from "vue";
import { Route } from "vue-router";
import { mdiImage } from "@mdi/js";
import { date } from "@/filters";

export interface DataType {
  checklist?: Checklist;
}

export default Vue.extend({
  filters: {
    date
  },
  data(): DataType {
    return {
      checklist: undefined
    };
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/checklists/${to.params.id}`;

    const { data } = await http.get<Checklist>(url);

    next((vm: DataType & Vue) => {
      vm.checklist = data;
    });
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.checklist == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "室内状況の確認",
          disabled: true
        }
      ];
    },
    icon() {
      return {
        mdiImage
      };
    }
  }
});
